<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <div class="is-align-items-center is-flex is-justify-content-space-between">
        <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h2>
      </div>
    </div>

    <a-form-item
      class="column is-12"
      :label="$t('RMS Name')"
      name="name"
    >
      <a-input v-model:value="form.name" :size="size" type="text" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Currency')" name="currency">
      <InputCurrency
        v-model:value-model="form.currency"
      />
    </a-form-item>
    <template v-if="!value.id">
      <a-form-item class="column is-12" :label="$t('Status')" name="status">
        <InputStatus
          v-model:value="form.status"
        />
      </a-form-item>
    </template>
    <a-form-item class="column is-12" :label="$t('Upper Limit')" name="upperLimit">
      <a-input-number v-model:value="form.upperLimit" :size="size" style="width: 100%;" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Pricing Strategy')" name="pricingStrategy">
      <a-select
        v-model:value="form.pricingStrategy"
        :size="size"
        show-search
        style="width: 100%"
      >
        <a-select-option v-for="op in pricingStrategies" :key="op.id">
          {{ $t(op.name) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Maximum Discount Rate')" name="maxDiscountRate">
      <a-input
        v-model:value.number="form.maxDiscountRate"
        type="number"
        :size="size"
        style="width: 100%;"
        suffix="%"
      />
    </a-form-item>

    <div class="column is-12 m-t-4x" :class="{ 'has-text-right': value }">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Add New RMS": "Add New RMS",
    "Edit RMS Detail": "Edit RMS Detail",
    "Currency": "Currency",
    "Status": "Status",
    "Upper Limit": "Upper Limit",
    "Pricing Strategy": "Pricing Strategy",
    "Maximum Discount Rate": "Maximum Discount Rate",
    "Please select": "Please select",
    "RMS Name": "RMS Name",
    "Start High and Discount": "Start High and Discount",
    "Start Low and Top up": "Start Low and Top up",
    "Property is required.": "Property is required.",
    "Currency is required.": "Currency is required.",
    "Status is required.": "Status is required.",
    "RMS Name is required.": "RMS Name is required.",
    "Upper limit is required.": "Upper limit is required.",
    "Pricing Strategy is required.": "Pricing Strategy is required.",
    "Max Discount is required.": "Max Discount is required.",
  },
  "ja": {
    "Add New RMS": "新しいRMSを追加",
    "Edit RMS Detail": "RMSを編集する",
    "Currency": "通貨",
    "Status": "予約区分",
    "Upper Limit": "上限金額",
    "Pricing Strategy": "価格戦略",
    "Maximum Discount Rate": "最大割引率",
    "RMS Name": "RMS名称",
    "Start High and Discount": "安値で初期設定し値上げ",
    "Start Low and Top up": "高値で初期設定し値下げ",
    "Property is required.": "物件は必須です",
    "Currency is required.": "通貨は必須です",
    "Status is required.": "ステータスは必須です",
    "RMS Name is required.": "RMS名称は必須項目です",
    "Upper limit is required.": "上限金額は必須項目です",
    "Pricing Strategy is required.": "価格戦略は必須項目です",
    "Max Discount is required.": "最大割引率は必須項目です",
  }
}
</i18n>

<script>
import InputProperty from '@/components/InputProperty';
import InputCurrency from '@/components/InputCurrency';
import InputStatus from '@/views/revenue-management/settings/components/InputStatus';
import { PRICING_STRATEGY } from '@/config/revenue-managements';

export default {
  name: 'RMSAddModal',
  components: {
    InputProperty,
    InputCurrency,
    InputStatus,
  },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      isSubmitting: false,
      form: {
        name: undefined,
        propertyId: undefined,
        currency: undefined,
        status: undefined,
        upperLimit: undefined,
        pricingStrategy: undefined,
        maxDiscountRate: undefined,
      },
      pricingStrategies: PRICING_STRATEGY,
      size: 'large',
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit RMS Detail')
        : this.$t('Add New RMS');
    },
    rules() {
      const rules = {
        name: [
          {
            required: true,
            message: this.$t('RMS Name is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        propertyId: [
          {
            required: true,
            message: this.$t('Property is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        currency: [
          {
            required: true,
            message: this.$t('Currency is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        upperLimit: [
          {
            type: 'number',
            required: true,
            message: this.$t('Upper limit is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        pricingStrategy: [
          {
            required: true,
            message: this.$t('Pricing Strategy is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        maxDiscountRate: [
          {
            type: 'number',
            required: true,
            message: this.$t('Max Discount is required.'),
            trigger: ['change', 'blur'],
          },
        ],
      };

      if (this.value && !this.value.id) {
        rules.status = [
          {
            required: true,
            message: this.$t('Status is required.'),
            trigger: ['change', 'blur'],
          },
        ];
      }

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        if (this.value.id) {
          const payload = { id: this.value.id, ...this.form };

          await this.$store.dispatch('rms-settings/editRms', payload);
        } else {
          await this.$store.dispatch('rms-settings/addRms', this.form);
        }
        this.$emit('close', { ...this.form });
        this.isSubmitting = false;
      } catch (e) {
        this.isSubmitting = false;
        console.log(e);
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
