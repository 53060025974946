<template>
  <a-select
    v-model:value="status"
    :size="size"
    :placeholder="$t('Status')"
    show-search
    style="width: 100%"
    @change="handleChange"
  >
    <a-select-option v-for="op in options" :key="op.id">
      {{ $t(op.name) }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Status": "Status",
    "Active": "Active",
    "Inactive": "Inactive",
  },
  "ja": {
    "Status": "ステータス",
    "Active": "有効",
    "Inactive": "無効",
  }
}
</i18n>

<script>
export default {
  name: 'InputStatus',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    valueModel: {
      type: String,
      default() {
        return undefined;
      },
    },
  },
  emits: ['change', 'update:valueModel'],
  data() {
    return {
      status: undefined,
      options: [
        {
          id: 'active',
          name: 'Active',
        },
        {
          id: 'inactive',
          name: 'Inactive',
        },
      ],
    };
  },
  watch: {
    valueModel: {
      immediate: true,
      handler(nv) {
        this.status = nv;
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.status);
      this.$emit('update:valueModel', this.status);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Status": "Status",
  },
  "ja": {
    "Status": "予約区分",
  }
}
</i18n>
