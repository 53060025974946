export const PRICING_STRATEGY = [
  {
    id: 'start high and discount',
    name: 'Start High and Discount',
  },
  {
    id: 'start low and top up',
    name: 'Start Low and Top up',
  },
];

export const PRICING_FRACTION = [
  {
    id: 'round up',
    name: 'round up',
  },
  {
    id: 'round down',
    name: 'round down',
  },
];

export const FRACTION_CONDITION = [
  {
    id: 'above',
    name: 'Above',
  },
  {
    id: 'below',
    name: 'Below',
  },
];

export const RATE_PLANS = {
  STANDARD: 'per-room-standard',
  EARLY_BIRD: 'early-bird',
  CONSECUTIVE_STAY: 'consecutive-stay',
};

export const RATE_TYPES = {
  ROOM: 'room',
  PERSON: 'person',
  PIOP_ROOM: 'piop-room',
};

export const RATE_PAX = {
  PAX1: 'pax1',
  PAX2: 'pax2',
  PAX3: 'pax3',
  PAX4: 'pax4',
  PAX5: 'pax5',
  PAX6: 'pax6',
};

export const TOPLINE_STATUS = [
  { id: 'checking', name: 'Checking' },
  { id: 'checked', name: 'Checked' },
  { id: 'tally_in_progress', name: 'Tally in Progress' },
];

export const TOPLINE_DATE = [
  { id: 'checkin', name: 'Check in date' },
  { id: 'checkout', name: 'Check out date' },
  { id: 'reservationDate', name: 'Reservation date' },
];
export const COST_UNITS = {
  PERCENTAGE: 'percentage',
  PRICE: 'price',
};

export const COVERS = {
  PER_DAY: 'per-day',
  PER_MAX_PAX: 'per-max-pax',
};
